//
// user.scss
// Use this to write your custom SCSS
//

.icon-phone-top {
  margin-left: 0.7rem !important;
}

.menu-cursor {
  cursor: pointer;
}

@media (max-width: 768px) {
  .sales-phone-for-mobile {
    display: flex !important;
    position: absolute;
  }
}

@media (max-width: 768px) {
  .image-banner {
    margin-left: -37px !important;
  }
}

