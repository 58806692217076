/*
 * Around | Multipurpose Bootstrap Template
 * Copyright 2021 Createx Studio
 * Theme styles
 */

// User variables
@import 'user-variables';

// Configuration & utilities
@import 'variables';
@import 'mixins';
@import 'utilities';
// Bootstrap
@import '../../dist/vendor/bootstrap/scss/bootstrap';

// Layout & components
@import 'reboot';
@import 'components';

// User custom styles
@import 'user';

.add-to-cart-new {
  --color: #61000d;
  --icon: var(--color);
  --cart: #61000d;
  --dots: #61000d;
  --background: #fff;
  --shadow: #{rgba(#242424, .16)};
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid #61000d;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  border-radius: 1rem;
  padding: 12px 32px;
  font-weight: 500;
  line-height: 20px;
  transform: scale(var(--s, .97));
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform .3s, box-shadow .3s;
  .default-new, {
    padding-left: 16px;
    position: relative;
    opacity: var(--o, 1);
    transform: scale(var(--s, 1));
    transition: transform .3s, opacity .3s;
    transition-delay: var(--d, .3s);
    &:before,
    &:after {
      content: '';
      width: 2px;
      height: 12px;
      left: 0;
      top: 4px;
      border-radius: 1px;
      background: var(--icon);
      position: absolute;
      transform: rotate(var(--r, 0deg));
      transition: transform .25s;
    }
    &:after {
      --r: 90deg;
    }
  }
  .success-new {
    width: 100%;
    opacity: var(--o, 0);
    transform: translate(-50%, var(--y, 12px));
    position: absolute;
    top: 12px;
    left: 50%;
    transition: opacity .3s, transform .3s;
    transition-delay: var(--d, 0s);
  }
  .dots-new {
    width: 4px;
    height: 4px;
    top: 20px;
    left: 60%;
    margin-left: -7px;
    border-radius: 2px;
    position: absolute;
    transform-origin: 10px 50%;
    background: var(--dots);
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
    opacity: var(--o, 0);
    transform: scale(var(--s, .4));
    transition: opacity .3s, transform .3s;
    transition-delay: var(--d, 0s);
  }
  .cart-new {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: inherit;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      background: var(--background);
      top: 17px;
      right: 100%;
      z-index: 1;
      margin-right: -20px;
      transform: translateX(-18px) rotate(-16deg);
    }
    & > div {
      top: 13px;
      right: 100%;
      transform: translateX(-18px) rotate(-16deg);
      position: absolute;
      z-index: 2;
      transform-origin: 1px 21px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: var(--t, 4px);
        left: var(--l, 0);
        height: 2px;
        width: var(--w, 18px);
        background: var(--cart);
        border-radius: 1px;
      }
      &:after {
        --w: 16px;
        --t: 14px;
        --l: 1px;
      }
      & > div {
        width: 2px;
        height: var(--h, 15px);
        border-radius: 1px;
        transform: rotate(var(--r, -8deg));
        background: var(--cart);
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background: inherit;
        }
        &:after {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          bottom: var(--b, -6px);
          left: var(--l, 0);
        }
        &:first-child {
          &:before {
            border-radius: inherit;
            top: 0;
            right: 0;
            height: 2px;
            width: 6px;
            transform-origin: 5px 1px;
            transform: rotate(16deg);
          }
        }
        &:last-child {
          --h: 12px;
          --r: 8deg;
          position: absolute;
          left: 16px;
          bottom: -1px;
          &:after {
            --l: -2px;
            --b: -5px;
          }
        }
      }
    }
  }
  &.added {
    .default-new {
      --o: 0;
      --s: .8;
      --d: 0s;
      &:before {
        --r: -180deg;
      }
      &:after {
        --r: -90deg;
      }
    }
    .dots-new {
      --o: 1;
      --s: 1;
      --d: .3s;
      animation: dots-new 2s linear forwards;
    }
    .success-new {
      --o: 1;
      --y: 0;
      --d: 1.8s;
    }
    .cart-new {
      &:before,
      & > div {
        animation: cart-new 2s forwards;
      }
    }
  }
  &:not(.added) {
    &:hover {
      --s: 1;
      --s-y: 8px;
      --s-b: 20px;
    }
    &:active {
      --s: .94;
      --s-y: 2px;
      --s-b: 6px;
    }
  }
}

@keyframes cart-new {
  41%,
  49%,
  57%,
  60% {
    transform: translateX(72px) rotate(0deg);
  }
  40%,
  47%,
  54% {
    transform: translateX(72px) rotate(0deg) translateY(1px);
  }
  100% {
    transform: translateX(180px) rotate(-16deg);
  }
}

@keyframes dots-new {
  5% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px) rotate(-32deg);
    box-shadow: 5px 0 0 var(--dots-new), 10px 0 0 var(--dots-new);
  }
  31% {
    box-shadow: 5px -4px 0 var(--dots-new), 10px -8px 0 var(--dots-new);
  }
  32%,
  50% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  45%,
  100% {
    box-shadow: 5px 0 0 var(--dots-new), 10px 0 0 var(--dots-new);
  }
  51%,
  100% {
    opacity: 0;
  }
}

.add-to-cart-in-cart {
  --color: #fff;
  --icon: var(--color);
  --cart: #fff;
  --dots: #fff;
  --background: #61000d;
  --shadow: #{rgba(#242424, .16)};
  cursor: pointer;
  position: relative;
  outline: none;
  border: 1px solid #61000d;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  border-radius: 1rem;
  padding: 12px 82px;
  font-weight: 500;
  line-height: 20px;
  transform: scale(var(--s, .97));
  box-shadow: 0 var(--s-y, 4px) var(--s-b, 12px) var(--shadow);
  color: var(--color);
  background: var(--background);
  transition: transform .3s, box-shadow .3s;
  .default-new, {
    padding-left: 16px;
    position: relative;
    opacity: var(--o, 1);
    transform: scale(var(--s, 1));
    transition: transform .3s, opacity .3s;
    transition-delay: var(--d, .3s);
    &:before,
    &:after {
      content: '';
      width: 2px;
      height: 12px;
      left: 0;
      top: 4px;
      border-radius: 1px;
      background: var(--icon);
      position: absolute;
      transform: rotate(var(--r, 0deg));
      transition: transform .25s;
    }
    &:after {
      --r: 90deg;
    }
  }
  .success-new {
    width: 100%;
    opacity: var(--o, 0);
    transform: translate(-50%, var(--y, 12px));
    position: absolute;
    top: 12px;
    left: 50%;
    transition: opacity .3s, transform .3s;
    transition-delay: var(--d, 0s);
  }
  .dots-new {
    width: 4px;
    height: 4px;
    top: 20px;
    left: 50%;
    margin-left: -7px;
    border-radius: 2px;
    position: absolute;
    transform-origin: 10px 50%;
    background: var(--dots);
    box-shadow: 5px 0 0 var(--dots), 10px 0 0 var(--dots);
    opacity: var(--o, 0);
    transform: scale(var(--s, .4));
    transition: opacity .3s, transform .3s;
    transition-delay: var(--d, 0s);
  }
  .cart-new {
    position: absolute;
    left: 16%;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: inherit;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      background: var(--background);
      top: 17px;
      right: 100%;
      z-index: 1;
      margin-right: -20px;
      transform: translateX(-18px) rotate(-16deg);
    }
    & > div {
      top: 13px;
      right: 100%;
      transform: translateX(-18px) rotate(-16deg);
      position: absolute;
      z-index: 2;
      transform-origin: 1px 21px;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: var(--t, 4px);
        left: var(--l, 0);
        height: 2px;
        width: var(--w, 18px);
        background: var(--cart);
        border-radius: 1px;
      }
      &:after {
        --w: 16px;
        --t: 14px;
        --l: 1px;
      }
      & > div {
        width: 2px;
        height: var(--h, 15px);
        border-radius: 1px;
        transform: rotate(var(--r, -8deg));
        background: var(--cart);
        position: relative;
        &:before,
        &:after {
          content: '';
          position: absolute;
          background: inherit;
        }
        &:after {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          bottom: var(--b, -6px);
          left: var(--l, 0);
        }
        &:first-child {
          &:before {
            border-radius: inherit;
            top: 0;
            right: 0;
            height: 2px;
            width: 6px;
            transform-origin: 5px 1px;
            transform: rotate(16deg);
          }
        }
        &:last-child {
          --h: 12px;
          --r: 8deg;
          position: absolute;
          left: 16px;
          bottom: -1px;
          &:after {
            --l: -2px;
            --b: -5px;
          }
        }
      }
    }
  }
  &.added {
    .default-new {
      --o: 0;
      --s: .8;
      --d: 0s;
      &:before {
        --r: -180deg;
      }
      &:after {
        --r: -90deg;
      }
    }
    .dots-new {
      --o: 1;
      --s: 1;
      --d: .3s;
      animation: dots-new 2s linear forwards;
    }
    .success-new {
      --o: 1;
      --y: 0;
      --d: 1.8s;
    }
    .cart-new {
      &:before,
      & > div {
        animation: cart-new 2s forwards;
      }
    }
  }
  &:not(.added) {
    &:hover {
      --s: 1;
      --s-y: 8px;
      --s-b: 20px;
    }
    &:active {
      --s: .94;
      --s-y: 2px;
      --s-b: 6px;
    }
  }
}

@keyframes cart-new {
  41%,
  49%,
  57%,
  60% {
    transform: translateX(72px) rotate(0deg);
  }
  40%,
  47%,
  54% {
    transform: translateX(72px) rotate(0deg) translateY(1px);
  }
  100% {
    transform: translateX(220px) rotate(-16deg);
  }
}

@keyframes dots-new {
  5% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-20px) rotate(-32deg);
    box-shadow: 5px 0 0 var(--dots-new), 10px 0 0 var(--dots-new);
  }
  31% {
    box-shadow: 5px -4px 0 var(--dots-new), 10px -8px 0 var(--dots-new);
  }
  32%,
  50% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  45%,
  100% {
    box-shadow: 5px 0 0 var(--dots-new), 10px 0 0 var(--dots-new);
  }
  51%,
  100% {
    opacity: 0;
  }
}

.page-loading {
  background-color: transparent;
}

.banner-img {
  background-size: cover;
}

.bg-blocks-1,.bg-blocks-2,.bg-blocks-3,.bg-blocks-4 {
  border-radius: 0 !important;
  box-shadow: none !important;
  text-align: center !important;
  margin-bottom: 6px !important;
}

.bg-blocks-1 {
  background-color: #f96b19 !important;
}
.bg-blocks-2 {
  background-color: #efe2d9 !important;
}
.bg-blocks-3 {
  background-color: #f49564 !important;

}
.bg-blocks-4 {
  background-color: #c0d0dd !important;
}

.text-blok {
  text-align: justify !important;
  color: #000000 !important;
}

.block-position {
  padding-left: calc(var(--bs-gutter-x) * .1);
  padding-right: calc(var(--bs-gutter-x) * .1);
}

.title-block {
  color: #000000 !important;
}

.lines-block {
  z-index: -1;
  opacity: 1 !important;
}

.banner {
  background-color: #91c7d1 !important;
  opacity: 1 !important;
}

.dog-and-cat {
  margin-top: 100px;
  margin-left: -35px;
}

@media (max-width: 768px) {
  .dog-and-cat {
    max-width: 90%;
  }
}

.form-question {
  background-color: #f5f5f5;
}

.top-sales,
.homeQuestionTitle{
  text-transform: uppercase;
}

.menuCatalog {
  padding-right: 40px !important;
}

.product-gallery-img {
  padding: 0 100px;
}

.title-cart {
  min-height: 4rem;
}
.marcersInComponents {
  list-style-type: none;
}

.alert-box {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.blocks-company {
  min-height: 330px;
}

.validateForm {
  border: 1px solid #61000d;
}

.error-massage {
  color: #61000d;
}
.error-massage-global {
  text-align: center !important;
  color: #61000d;
}

.alertSuccess {
  display: none;
}

.alertSuccessOrder {
  display: none;
}

.gallery-article {
  border-radius: 1rem;
}

.loginWindow {
  margin: auto;
  max-width: 600px;
}

.width-container {
  max-width: 100%;
}
.opasity-menu {
  opacity: .985;
}

.top-sales-admin {
  margin: auto;
}

.margin-button-edit {
  margin-left: 3rem;
}

.margin-button-articles {
  margin-left: 2rem;
}

.switch-isActive {
  cursor: pointer;
}

.active-menu {
  cursor: pointer !important;
  pointer-events: auto !important;
}

.articles-height {
  min-height: 120px;
}

.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
}

.stringForProducts {
  margin: 2px 1px;
}

.marginProductFromListInOrder {
  margin: auto !important;
}

.sumOrder {
  margin-left: 1rem;
}

.dropdown-mega {
  position: relative !important;
}

.masonry {
  column-count: 2;
  column-gap: 5px;
  @media (min-width: 768px) {
    column-count: 2;
  }
  @media (min-width: 992px) {
    column-count: 3;
  }
  @media (min-width: 1199px) {
    column-count: 3;
  }
  .brick {
    box-sizing: border-box;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    counter-increment: brick-counter;
    margin: .5rem
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card-hover {
  overflow:hidden;
}

.gift-span
{
  padding: 0.5em 0.5em!important;
  font-size: 0.68em!important;
  //left: -39px!important;
  //width: 150px!important;
  background-color: #61000d!important;
}

.badge-floating-right {
  right: -1px;
  position: absolute;
  min-width: 4rem;
  top: 1.5rem;
  z-index: 5;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.scoreboard {
  width: 100%;
  margin: 20px auto;
  overflow-y: auto;
  padding: 20px 20px 10px 20px;
  border-radius: 3px;
}

.goal {
  list-style-type: none;
  padding: 10px;
  border: 1px solid #a3a3a3;
  margin-bottom: 10px;
  border-radius: 3px;
  cursor: move;
}
.sortable-ghost {
  opacity: 0.2;
}

.thumbnails-btn-inline {
  display: inline-block;
}

.preview-for-product {
  min-height: 485px;
}
.preview-for-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    width: 400px;
    height: 225px;
  }
  @media (min-width: 992px) {
    width: 500px;
    height: 294px;
  }
  @media (min-width: 1199px) {
    width: 100%;
    height: 325px;
  }
}
